<template>
    <div>
      <b-container fluid>
        <b-row>
          <b-col cols="12" md="12" lg="12">
            <b-card header-tag="header" header-bg-variant="dark">
              <template #header>
                <h5 class="mb-0" style="color: #fff">
                  <strong>Edit Data Tindakan Laboratorium</strong>
                </h5>
              </template>
              <b-row>
                <b-col cols="8" md="8" lg="8">
                    <b-form>
                        <b-form-group label-cols-md="3">
                            <template v-slot:label>
                                Nama Kategori <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                                :state="checkIfValid('nama_tindakan_laboratorium')"
                                type="text"
                                v-model="$v.is_data.nama_tindakan_laboratorium.$model"
                                disabled
                            ></b-form-input>
                        </b-form-group>
                    </b-form>
                    <hr>
                    <b-form>
                    <b-form-group label-cols-md="3">
                        <template v-slot:label>
                            Nama Tindakan Lab
                        </template>
                        <b-form-input
                        type="text"
                        v-model="nama_sub_tindakan_lab"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group label-cols-md="3">
                        <template v-slot:label>
                            Satuan
                        </template>
                        <Multiselect
                            v-model="satuan_lab"
                            :options="options_satuan"
                            :multiple="false"
                            :searchable="true"
                            :close-on-select="true"
                            :show-labels="false"
                            label="nama_satuan_lab"
                            track-by="ms_komponen_tarif_id"
                            placeholder="-- Pilih Satuan --"
                            size="sm"
                        ></Multiselect>
                    </b-form-group>
                    <b-form-group label-cols-md="3">
                        <template v-slot:label>
                        Harga Tindakan Lab
                        </template>
                        <b-form-input
                        type="number"
                        v-model="harga_tindakan_lab"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group class="mt-2">
                        <b-button
                            variant="primary"
                            @click="simpanSub()"
                            >Simpan Tindakan</b-button
                        >
                    </b-form-group>
                    </b-form>
                </b-col>
              </b-row>
  
              <b-row class="mt-2">
                    <b-col cols="12" md="12" lg="12">
                    <b-table
                        :items="is_data.bulk_sub_tindakan_lab"
                        :fields="fields"
                        responsive
                        show-empty
                        small
                        hover
                        ref="table"
                    >
                        <template #cell(actions)="row">
                        <b-button
                            variant="danger"
                            class="mr-1"
                            v-c-tooltip.hover.click="'Hapus Data'"
                            v-b-modal.modal-delete-sub-tindakan
                            @click="datanya = row.item"
                            ><CIcon name="cil-trash" /> {{ row.actions }}</b-button
                        >
                        <b-button
                            variant="warning"
                            class="mr-1"
                            v-c-tooltip.hover.click="'Edit Tindakan'"
                            v-b-modal.modal-edit-sub-tindakan
                            @click="datanya = row.item"
                            ><CIcon name="cil-pencil" /> {{ row.actions }}</b-button
                        >
                        <b-button
                            variant="secondary"
                            class="mr-1"
                            v-c-tooltip.hover.click="'Tambah Komponen Tarif'"
                            @click="addKomponen(row.item, row.index, $event.target)"
                            ><CIcon name="cil-pencil" /> {{ row.actions }}</b-button
                        >
                        </template>
                        <template #cell(bulk_tarif_sub_tindakan)="item">
                            <b-row>
                                <b-col>
                                    <ul>
                                        <li v-for="(itemnya, index) in item.item.bulk_tarif_sub_tindakan" :key="index">{{ itemnya.nama_komponen_tarif }} : {{ itemnya.harga_pool_tarif_sub_tindakan }}</li>
                                    </ul>
                                </b-col>
                            </b-row>
                        </template>
                    </b-table>
                    </b-col>
                </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
      <!-- MODAL KOMPONEN -->
      <b-modal id="modal-add-komponen-edit" hide-header size="lg" centered title="Tambah Komponen">
        <b-row>
            <b-col>
                <b-form>
                    <b-form-group label-cols-md="3">
                        <template v-slot:label>
                            Komponen Tarif
                        </template>
                        <Multiselect
                            v-model="komponen_tarif"
                            :options="options_komponen"
                            :multiple="false"
                            :searchable="true"
                            :close-on-select="true"
                            :show-labels="false"
                            label="nama_komponen_tarif"
                            track-by="ms_komponen_tarif_id"
                            placeholder="-- Pilih Komponen --"
                            size="sm"
                        ></Multiselect>
                    </b-form-group>
                    <b-form-group label-cols-md="3">
                        <template v-slot:label>
                            Harga Tarif Komponen
                        </template>
                        <b-form-input
                            type="number"
                            v-model="harga_pool_tarif_sub_tindakan"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group class="mt-2">
                        <b-button
                            variant="primary"
                            @click="simpanKomponen()"
                            >Simpan Komponen</b-button
                        >
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-table
                    :items="bulk_tarif_sub_tindakan"
                    :fields="fieldsKomponen"
                    responsive
                    show-empty
                    small
                    hover
                >
                <template #cell(actions)="row">
                    <b-button
                        variant="danger"
                        class="mr-1"
                        v-c-tooltip.hover.click="'Hapus Data'"
                        v-b-modal.modal-delete-komponen
                        @click="datanya = row.item, indexnya = row.index"
                        ><CIcon name="cil-trash" /> {{ row.actions }}</b-button
                    >
                </template>
                
                </b-table>
            </b-col>
        </b-row>
        <template #modal-footer>
          <b-button variant="secondary" @click="$bvModal.hide('modal-add-komponen-edit')">
            Close
          </b-button>
        </template>
      </b-modal>
      <ModalEditSub
        :data_edit="datanya"
        :options_satuan="options_satuan"
        @alertFromChild="triggerAlert($event)"
        @tutupModal="getDatas()"
      />
      <ModalDeleteSub
        :data_delete="datanya"
        @alertFromChild="triggerAlert($event)"
        @tutupModal="getDatas()"
      />
      <ModalDeleteKomponen
        :data_delete="datanya"
        @alertFromChild="triggerAlert($event)"
        @deleteData="deleteRowKomponen()"
        @tutupModal="getDatas()"
      />
    </div>
  </template>
  
  <script>
  import { validationMixin } from "vuelidate";
  import { required } from "vuelidate/lib/validators";
  import ModalEditSub from "./modal_edit_sub_tindakan.vue";
  import ModalDeleteSub from "./modal_delete_sub_tindakan.vue";
  import ModalDeleteKomponen from "./modal_delete_komponen.vue";
  export default {
    name: "edit_tindakan_lab",
    components: {
        ModalEditSub,
        ModalDeleteSub,
        ModalDeleteKomponen
    },
    data() {
      return {
        showing: false,
        variant: "success",
        msg: "",
        data_edit: null,
        datanya: null,
        satuan_lab: null,
        is_data: {
          nama_tindakan_laboratorium: "",
          bulk_sub_tindakan_lab: [],
        },
        nama_sub_tindakan_lab: "",
        harga_tindakan_lab: "",
        komponen_tarif: "",
        harga_pool_tarif_sub_tindakan: "",
        indexnya: null,
        options_komponen: [],
        options_satuan: [],
        bulk_tarif_sub_tindakan: [],
        sub_tindakan_id: null,
        fields: [
          {
            key: "nama_sub_tindakan_lab",
            label: "Nama",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },

          {
            key: "harga_tindakan_lab",
            label: "Harga Tindakan",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },

          {
            key: "nama_satuan_lab",
            label: "Satuan",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },
          
          {
            key: "bulk_tarif_sub_tindakan",
            label: "Komponen Tarif",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },

          {
            key: "actions",
            label: "Actions",
            class: "table-option-4 text-center",
          },
        ],
        fieldsKomponen: [
        {
            key: "nama_komponen_tarif",
            label: "Nama Komponen",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },

          {
            key: "harga_pool_tarif_sub_tindakan",
            label: "Harga Tarif",
            sortable: true,
            sortDirection: "desc",
            class: "text-left",
          },

          {
            key: "actions",
            label: "Actions",
            class: "table-option-2 text-center",
          },
        ],
        items: [],
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        filter: null,
        // filterOn: [],
        tableBusy: false,
      };
    },
    computed: {
      formString() {
        return JSON.stringify(this.is_data, null, 4);
      },
      isValid() {
        return !this.$v.is_data.$invalid;
      },
      isDirty() {
        return this.$v.is_data.$anyDirty;
      },
      id_tindakan() {
        return this.$route.query ? this.$route.query.id : null;
      },
    },
    mixins: [validationMixin],
    validations: {
      is_data: {
        nama_tindakan_laboratorium: { required },
      },
    },
    activated(){
        this.totalRows = this.items.length;
      this.getDatas();
      this.setData()
 
    },
    async mounted() {
      // Set the initial number of items
      this.totalRows = this.items.length;
      this.getDatas();
      this.setData()

    },
    methods: {
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      checkIfValid(fieldName) {
        const field = this.$v.is_data[fieldName];
        if (!field.$dirty) {
          return null;
        }
        return !(field.$invalid || field.$model === "");
      },
      async getDatas(){
        let vm = this
        let komponen_tarif = await vm.$axios.post("/ms_komponen_tarif/list")
        // console.log(komponen_tarif, 'ini komponen');
        vm.options_komponen = komponen_tarif.data.data
        let satuan_lab = await vm.$axios.post("/ms_satuan_lab/list")
        // console.log(satuan_lab, 'log satuan lab');
        vm.options_satuan = satuan_lab.data.data
        // for (let i = 0; i < komponen_tarif.data.data.length; i++) {
        //     let x = komponen_tarif.data.data[i];
        //     let penampung = {}
        //     penampung.value = x.ms_komponen_tarif_id
        //     penampung.text = x.nama_komponen_tarif
        //     vm.options_komponen.push(penampung)
        // }
        // console.log(vm.options_komponen, 'ini options');
      },
      setData(){
        let vm = this
        // vm.data_edit = vm.$store.state.data_tindakan_lab
        // vm.is_data.nama_tindakan_laboratorium = vm.data_edit.nama_tindakan_laboratorium
        // vm.is_data.bulk_sub_tindakan_lab = vm.data_edit.bulk_sub_tindakan_lab
        vm.$axios('/tindakan_lab/details_by_id/' + vm.id_tindakan)
        .then((res) => {
            vm.data_edit = res.data.data[0]
            console.log(vm.data_edit, 'ini ress set');
            vm.is_data.nama_tindakan_laboratorium = vm.data_edit.nama_tindakan_laboratorium
            vm.is_data.bulk_sub_tindakan_lab = vm.data_edit.bulk_sub_tindakan_lab
            // vm.$forceUpdate()
        })
        .catch((err) => {
            console.log(err);
        })
        // console.log(vm.data_edit, 'ini data edit');
      },
      addKomponen(item){
        let vm = this
        console.log(item, 'ini');
        vm.sub_tindakan_id = item.sub_tindakan_lab_id
        if(item.bulk_tarif_sub_tindakan){
            vm.bulk_tarif_sub_tindakan = item.bulk_tarif_sub_tindakan
        }
        vm.$bvModal.show('modal-add-komponen-edit')
      },
      simpanSub(){
        let vm = this
        // let penampung = {}
        // penampung.nama_sub_tindakan_lab = vm.nama_sub_tindakan_lab
        // penampung.harga_tindakan_lab = vm.harga_tindakan_lab
        // vm.is_data.bulk_sub_tindakan_lab.push(penampung)
        let satuan_id = vm.satuan_lab.ms_satuan_lab_id
        vm.$axios.post("/sub_tindakan_lab/register", {
            nama_sub_tindakan_lab : vm.nama_sub_tindakan_lab,
            harga_tindakan_lab : vm.harga_tindakan_lab,
            ms_satuan_lab_id : satuan_id,
            tindakan_laboratorium_id : vm.data_edit.tindakan_laboratorium_id
        })
        .then((res) => {
            console.log(res, 'ini ress');
            if(res.data.status == 200 && res.data.message == "sukses"){
                vm.$store.commit('set_alert', {msg: "BERHASIL MENAMBAHKAN SUB TINDAKAN", variant: "success", showing: true})
                vm.setData()
            }
        })
        .catch((err) => {
            console.log(err);
        })
        vm.nama_sub_tindakan_lab = ""
        vm.harga_tindakan_lab = ""
        vm.satuan_lab = ""
      },
      simpanKomponen(){
        let vm = this
        let penampung = {}
        penampung.nama_komponen_tarif = vm.komponen_tarif.nama_komponen_tarif
        penampung.ms_komponen_tarif_id = vm.komponen_tarif.ms_komponen_tarif_id
        penampung.harga_pool_tarif_sub_tindakan = vm.harga_pool_tarif_sub_tindakan
        vm.bulk_tarif_sub_tindakan.push(penampung)
        vm.$axios.post("/pool_tarif_sub_tindakan/register", {
            ms_komponen_tarif_id : vm.komponen_tarif.ms_komponen_tarif_id,
            harga_pool_tarif_sub_tindakan : vm.harga_pool_tarif_sub_tindakan,
            sub_tindakan_lab_id : vm.sub_tindakan_id
        })
        .then((res) => {
            console.log(res, 'ini ress');
            if(res.data.status == 200 && res.data.message == "sukses"){
                vm.$store.commit('set_alert', {msg: "BERHASIL MENAMBAHKAN KOMPONEN TARIF", variant: "success", showing: true})
                vm.setData()
            }
        })
        .catch((err) => {
            console.log(err);
        })
        vm.komponen_tarif = ""
        vm.harga_pool_tarif_sub_tindakan = ""
      },
      async simpanFinalData(){
        let vm = this
        // console.log(vm.is_data, 'ini datanyaa');
        let reg = await vm.$axios.post("/tindakan_lab/register", vm.is_data)
        console.log(reg, 'ini hasilnyaa');
        if(reg.data.status == 200 && reg.data.message == "sukses"){
            vm.$store.commit('set_alert', {msg: "SUKSES MENDAFTARKAN TINDAKAN LABORATORIUM", variant: "success", showing: true})
            vm.$router.push({
                path: "/laboratorium/tindakan_lab",
                // query: { id_ba : data.item.id },
            }); 
        }
      },
      deleteRow(x,y){
        // console.log(x, y);
        let index = y
        this.is_data.bulk_sub_tindakan_lab.splice(index, 1)
      },
      deleteRowKomponen(){
        // console.log(x, y);
        this.bulk_tarif_sub_tindakan.splice(this.indexnya, 1)
      },
      triggerAlert(event) {
        let vm = this;
        vm.$store.commit("set_alert", event);
        vm.setData()
      },
    },
  };
  </script>
  
<template>
    <div>
      <b-modal
        id="modal-edit-sub-tindakan"
        size="lg"
        centered
        title="Update Data Sub Tindakan"
        header-bg-variant="warning"
        header-text-variant="light"
        @hidden="tutupModal"
      >
        <b-form>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Nama Sub Tindakan <span class="text-danger">*</span>
            </template>
            <b-form-input
              :state="checkIfValid('nama_sub_tindakan_lab')"
              type="text"
              v-model="$v.is_data.nama_sub_tindakan_lab.$model"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
                Satuan
            </template>
            <Multiselect
                v-model="ms_satuan_lab_id"
                :options="options_satuan"
                :multiple="false"
                :searchable="true"
                :close-on-select="true"
                :show-labels="false"
                label="nama_satuan_lab"
                track-by="ms_satuan_lab_id"
                placeholder="-- Pilih Satuan --"
                size="sm"
            ></Multiselect>
          </b-form-group>
          <b-form-group label-cols-md="3">
            <template v-slot:label>
              Harga Tindakan <span class="text-danger">*</span>
            </template>
            <b-form-input
              :state="checkIfValid('harga_tindakan_lab')"
              type="number"
              v-model="$v.is_data.harga_tindakan_lab.$model"
            ></b-form-input>
          </b-form-group>
        </b-form>
        <template #modal-footer>
          <b-button variant="secondary" @click="$bvModal.hide('modal-edit')">
            Batal
          </b-button>
          <b-button
            variant="primary"
            :disabled="busy || !isValid"
            @click="simpan()"
            >{{ button }}</b-button
          >
        </template>
      </b-modal>
    </div>
  </template>
  
  <script>
  import { validationMixin } from "vuelidate";
  import { required } from "vuelidate/lib/validators";
  
  export default {
    name: "modalEditSub",
    props:[
      'data_edit',
      'options_satuan',
    ],
    data() {
      return {
        busy: false,
        button: "Simpan",
        ms_satuan_lab_id: "",
        is_data: {
            nama_sub_tindakan_lab: "",
            harga_tindakan_lab: "",
            ms_satuan_lab_id: "",
        },
      };
    },
    computed: {
      computed_val: {
        get() {
          return this.is_data;
        },
        set(newVal) {
          this.is_data = newVal;
        },
      },
      formString() {
        return JSON.stringify(this.data_edit, 4, null);
      },
      isValid() {
        return !this.$v.is_data.$invalid;
      },
      isDirty() {
        return this.$v.is_data.$anyDirty;
      },
    },
    watch: {
      // data_edit(newVal) {
      //   const vm = this
      //   vm.is_data = newVal;
      //   vm.is_data.ms_satuan_lab_id = vm.$findKey(vm.options_satuan, vm.data_edit, true, 'ms_satuan_lab_id')
      //   console.log(vm.is_data)
      // },
      data_edit: {
        handler(data) {
          const vm = this
          vm.is_data = data
          vm.ms_satuan_lab_id = vm.$findKey(vm.options_satuan, data.ms_satuan_lab_id, true, 'ms_satuan_lab_id')
          console.log(vm.is_data, 'data');
        },
        deep: true
      },
    },
  
    mixins: [validationMixin],
    validations: {
      is_data: {
        nama_sub_tindakan_lab: { required },
        harga_tindakan_lab: { required },
      },
    },
    methods: {
      checkIfValid(fieldName) {
        const field = this.$v.is_data[fieldName];
        if (!field.$dirty) {
          return null;
        }
        return !(field.$invalid || field.$model === "");
      },
      simpan() {
        let vm = this;
        vm.busy = true;
        vm.button = "Mohon Tunggu";
        vm.$axios
          .post("/sub_tindakan_lab/update", {
            id: vm.is_data.sub_tindakan_lab_id,
            nama_sub_tindakan_lab: vm.is_data.nama_sub_tindakan_lab,
            harga_tindakan_lab: vm.is_data.harga_tindakan_lab,
            tindakan_laboratorium_id : vm.is_data.tindakan_laboratorium_id,
            ms_satuan_lab_id : vm.ms_satuan_lab_id.ms_satuan_lab_id
          }).then((res) => {
            if (res.data.message == "sukses") {
              vm.button = "Simpan";
              vm.busy = false;
              vm.$emit("alertFromChild", {
                variant: "success",
                msg: "BERHASIL MENGUBAH DATA SUB TINDAKAN",
                showing: true,
              });
              vm.$bvModal.hide("modal-edit-sub-tindakan");
            } else {
              vm.button = "Simpan";
              vm.busy = false;
              vm.$emit("alertFromChild", {
                variant: "danger",
                msg: (res.data.message).toUpperCase(),
                showing: true,
              });
            }
          })
          .catch(() => {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: "TERJADI KESALAHAN PADA SERVER",
              showing: true,
            });
          });
      },
      tutupModal() {
        this.$emit("tutupModal");
      },
    },
  };
  </script>
  